import React from 'react';

  class BallsApp extends React.Component {
    constructor(props) {
      super(props);
      this.canvasRef = React.createRef();
      this.shouldRun = false;
    }
  
    componentWillUnmount() {
      this.shouldRun = false;
    }

    componentDidMount() {
      const canvas = this.canvasRef.current;
      const { balls } = this.props;
      canvas.width  = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      balls.forEach(ball => {
        ball.x = ((Math.random() * (canvas.width-ball.radius)));
        ball.y = ((Math.random() * (canvas.height-ball.radius)));
      })
      this.shouldRun = true
      setInterval(() => {
          if(this.shouldRun) {
            this.drawBalls(); 
          }
      }, 1);
    }  
  
    componentDidUpdate() {
      this.drawBalls();
    }
  
    drawBalls() {
      const { balls } = this.props;
      const canvas = this.canvasRef.current;
      const context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
  
      context.filter = 'blur(10px)'

      balls.forEach(ball => {
        const safeDistance = ball.radius * 10;
        context.fillStyle = ball.color;
        context.beginPath();
        context.arc(ball.x, ball.y, ball.radius, 0, 2 * Math.PI);
        context.closePath();
        context.fill();
        
        let rightTouch, bottomTouch, leftTouch, topTouch;
        function doBounceIfNeeded() {
          rightTouch = ball.x >= canvas.width - ball.radius;
          bottomTouch = ball.y >= canvas.height - ball.radius;
          leftTouch = ball.x <= ball.radius;
          topTouch = ball.y <= ball.radius;
  
          if (rightTouch || leftTouch) {
            ball.toRight = !ball.toRight;
          }
          if (bottomTouch || topTouch) {
            ball.toBottom = !ball.toBottom;
          }
        }
  
        if (ball.toRight && ball.toBottom) {
          ball.x += ball.dx;
          ball.y += ball.dy;
          ball.iteration++;
          if (
            ball.iteration >= safeDistance / ball.dy - ball.radius ||
            ball.iteration >= safeDistance / ball.dx - ball.radius
          ) {
          }
          doBounceIfNeeded();
        } else if (!ball.toRight && ball.toBottom) {
          ball.x -= ball.dx;
          ball.y += ball.dy;
          ball.iteration++;
          if (
            ball.iteration >= safeDistance / ball.dy - ball.radius ||
            ball.iteration >= safeDistance / ball.dx - ball.radius
          ) {
          }
          doBounceIfNeeded();
        } else if (!ball.toRight && !ball.toBottom) {
          ball.x -= ball.dx;
          ball.y -= ball.dy;
          ball.iteration++;
          if (
            ball.iteration >= safeDistance / ball.dy - ball.radius ||
            ball.iteration >= safeDistance / ball.dx - ball.radius
          ) {
          }
          doBounceIfNeeded();
        } else if (ball.toRight && !ball.toBottom) {
          ball.x += ball.dx;
          ball.y -= ball.dy;
          ball.iteration++;
          if (
            ball.iteration >= safeDistance / ball.dy - ball.radius ||
            ball.iteration >= safeDistance / ball.dx - ball.radius
          ) {
          }
          doBounceIfNeeded();
        }
      });
    }
  
    render() {
      return <canvas filter="blur(40px)" className="canvas" ref={this.canvasRef}></canvas>;
    }
  }
  
  export default BallsApp;

