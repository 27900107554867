import React, {useRef} from 'react'
import { getVariable } from '../service/waco';
import folderCheck from "../assets/images/folder_check.png"
import folderEmpty from "../assets/images/folder_empty.png"
import folderQuestion from "../assets/images/folder_question.png"
import tallMan from "../service/tallMan.js" 
import Segment from "../components/simpleSegment";

const Switch = ({ content, facts, setPopUp, showFacts, onSelected }) => {
    let c = content;
    let onChange = (event) => {
        let id = event.target.name;
        let value = event.target.checked ? "Yes" : "No";
        onSelected(id, { text: value });
    }
    let onChange2 = (event) => {
        let id = event.target.name;
        let value = event.target.checked ? true: false;
        onSelected(id, { text: value });
    }

    var cur_icon;
    if(showFacts) {
        if(typeof(facts) == 'undefined') {
            cur_icon = folderQuestion;
        } else if(facts != null) {
            cur_icon = folderCheck;
        } else if(facts == null) {
            cur_icon = folderEmpty;
        } else {
            cur_icon = folderEmpty;
        }
    }

    const el = (sel, par) => (par||document).querySelector(sel)
    const elArea = useRef();
    const elPopup = useRef();
    console.log("test " + elPopup);

    let onIconClick = (event) => {
        setPopUp(destroyPopUp);
        elPopup.current.style.display = "block";

        let bounds = elArea.current.getBoundingClientRect()
        let bounds2 = elPopup.current.getBoundingClientRect()
        let evtBounds = event.target.getBoundingClientRect()
        var xAdjust = window.innerWidth/6;

        // console.log("test area " + (event.clientX - bounds.left ) + "   " + bounds2.height + " evtbou  " + bounds2.offsetHeight)
        
        elPopup.current.style.left = (event.clientX - xAdjust - bounds2.width) + 'px';
        elPopup.current.style.top = (event.clientY + window.scrollY - 330) + 'px';

        var url = new URL(window.location.href);
        var cx = url.searchParams.get("context");
        if (cx) {
            var datas = cx.split('.');
            if (datas.length > 2) {
                var params = atob(datas[1]);
                var data = JSON.parse(params);
                console.log(data);
  
                elPopup.current.contentWindow.postMessage({
                    version: 1,
                    method: "showFacts",
                    source: data.source,
                    fact: c.id,
                    data: facts,
                    authorization: "Bearer " + cx
                }, "*");
            }
        }
    }

    let destroyPopUp = () => {
        elPopup.current.style.display = "none";
    }

    return (
        
        <div id="med_switch" ref={elArea} className="question question-content" key={c.text}>
        <iframe id={"popup_" + c.id} ref={elPopup} className='question question-content popup' src="https://launch.clinicalalgorithms.com/facts/" />
        {c.id != "medication_history" ? 
            (getVariable(c.id) == true ? (
                <div className='form-check form-switch form-switch-md'>
                    <input type="checkbox" className="form-check-input" id={c.id} name={c.id} value={c.text} onChange={onChange2} checked/>
                    <label className='form-check-label' htmlFor={c.id}>
                        <h5 style={{paddingTop:"5px", paddingLeft:"8px"}}>{tallMan.getTallMed(c.text)}</h5>
                    </label>
                    <a onClick={onIconClick} style={{width:"7%", float:"right", cursor:"pointer"}}><img style={{width:"100%", float:"right"}} src={cur_icon}/></a>
                </div>
                ) : (
                <div className='form-check form-switch form-switch-md'>
                    <input type="checkbox" className="form-check-input" id={c.id} name={c.id} value={c.text} onChange={onChange2} />
                    <label className='form-check-label' htmlFor={c.id}>
                        <h5 style={{paddingTop:"5px", paddingLeft:"8px", margin:"0"}}>{tallMan.getTallMed(c.text)}</h5>
                    </label>
                    <a onClick={onIconClick} style={{width:"7%", float:"right", cursor:"pointer"}}><img style={{width:"100%", float:"right"}} src={cur_icon}/></a>
                </div>
                )
            
        ) : (
            <div>
                <h4 style={{paddingBottom:"5px"}}>{c.text}</h4>
            </div>
        ) }
        {c.choices.map((o) => {
            
            if(getVariable(c.id) == "Yes") {
                return (<div key={o.text} style={{width:"96.1%", marginLeft:"5px"}}>
                    <div className='form-check form-switch form-switch-md'>
                        <h5 style={{display:"inline-block", marginTop:"5px", paddingLeft:"8px"}}>{o.text}</h5>
                        <input type="checkbox" className="form-check-input" id={o.text} name={c.id} value={o.text} onChange={onChange} checked/>
                        <label className="form-check-label" htmlFor={o.text} />
                    </div>
                </div>)
            } else {
                return (<div key={o.text} style={{width:"96%.2", marginLeft:"5px"}}>
                    <div className='form-check form-switch form-switch-md'>
                        <h5 style={{display:"inline-block", marginTop:"5px", paddingLeft:"8px"}}>{o.text}</h5>
                        <input type="checkbox" className="form-check-input" id={o.text} name={c.id} value={o.text} onChange={onChange} />
                        <label className="form-check-label" htmlFor={o.text} />
                    </div>
                </div>)
            }
        })}
        
        <div className='grid-container' style={{gridTemplateColumns:"repeat(auto-fit, minmax(min-content, 48%))", padding:"3px"}}>
        {c.groups.map((g) => {
            return (<Segment content={g} onSelected={onSelected} key={g.type + g.id} type={g.type} />);
        })}</div>
    </div>)
};

export default Switch