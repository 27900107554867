import React, { Component, useState } from 'react'
import { Document, Page } from 'react-pdf';
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

function getWidth() {
    var scWidth = Math.max(document.body.scrollWidth, document.documentElement.scrollWidth, document.body.offsetWidth, document.documentElement.offsetWidth, document.documentElement.clientWidth);

    if(scWidth*0.65 > 1110) {
        scWidth = 1000;
    } else {
        scWidth = scWidth*0.65;
    }

    return scWidth;
}
  
class FLoatingPDF extends Component{

    constructor(props) {
        super(props);
        this.childRef = React.createRef();
        this.state = {
            file: null,
            visible:false
        }

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        let pdf = {url:'/assets/' + this.props.pdfName + '.pdf'};
        this.setState({file:pdf});
    }

    componentDidUpdate(prevProps) {
        let pdf = {url:'/assets/' + this.props.pdfName + '.pdf'};
        if (this.props.pdfName !== prevProps.pdfName) {
            this.setState({file:pdf});
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target) && this.props.isHidden == "visible") {
            this.props.togglePDF()
        }
    }

    render() {
        return(
            <div ref={this.wrapperRef} className="floatingPDF" style={{width:getWidth(), visibility:this.props.isHidden}}>
            <div className="card" style={{width:getWidth(), padding:"30px 15px 0px 15px"}}>
                <div style={{width:"100%"}}><button className='btn btn-primary' style={{width:"1%", float:"right", padding:"10px 30px 10px 20px"}} onClick={this.props.togglePDF}>X</button></div>
                <a target="_blank" href={'/assets/' + this.props.pdfName + '.pdf'}>
                <Document 
                    file={this.state.file}
                    onLoadSuccess={() => console.log("file loaded")}
                    options={{ withCredentials: true }}
                >
                    <Page pageNumber={1} width={(getWidth())-20} renderInteractiveForms={false} />
                </Document>
                </a>
            </div>
            </div>
        )
    }
    
}

export default FLoatingPDF