import React from 'react'

const Footer = ({ subtext }) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <footer>
                        {subtext ? subtext : null}
                        <hr />
                        <p>
                            Disclaimer: The decision support tools included are designed to be informational and to reflect current accepted practices at the time of publication. These tools are not intended to replace professional medical judgment or treatment of specific conditions. Heart of Texas Community Health Center (dba Waco Family Medicine) and the authors accept no responsibility for any damages, obligations, losses, liabilities, costs or expenses arising from use of the decision support tools contained herein.</p>
                    </footer>
                </div>
            </div>
        </div>
    )
};

export default Footer