import React, { useState } from 'react'

var weight;
var height;
var inches;

const BMI = ({ content, setBMI }) => {

    const [useMetric, setMetric] = useState(false)
    
    let changeValue = (event) => {
        switch(event.target.name) {
            case "weight":
                weight = parseFloat(event.target.value);
            break;
            case "height":
                height = parseFloat(event.target.value);
            break;
            case "inches":
                inches = parseFloat(event.target.value);
            break;
        }
        calculateChange();
    }

    let calculateChange = () => {
        if(useMetric) {
            if(height != null && weight != null) {
                let bmi = weight/(Math.pow(height*100, 2));
                setBMI(content.id, bmi);
            }
        } else {
            if(height != null && weight != null && inches != null) {
                let bmi = Math.round((weight*0.453592)/Math.pow((((height*12) + inches) * 0.0254), 2) * 10) / 10.0;
                setBMI(content.id, bmi);
            }
        }
    }

    return (
        <div className={"card "} style={{padding:"10px"}}>
            <div>
                <div style={{display:"flex"}}>
                    <h3 style={{flexGrow:"1", padding:"0", marginTop:"10px"}}><b>Weight</b></h3>
                    <div className='form-check form-switch form-switch-lg'>
                        <h3 style={{display:"inline-block", marginRight:"50px", marginTop:"10px", padding:"0"}}>Metric</h3>
                        <input type="checkbox" class="form-check-input" id={"Metric"} name={"Metric"} value={"Metric"} onChange={(e) => {setMetric(e.target.checked)}} />
                        <label className="form-check-label" htmlFor={"Metric"} style={{paddingTop:"8px"}}>
                        </label>
                    </div>
                </div>
                <div>
                    <h3 style={{display:"inline-block", marginTop:"10px", width:"23%", minWidth:"150px", padding:"0"}}>{useMetric ? "Kilograms" : "Pounds"}</h3>
                    <input style={{display:"inline-block", width:"auto"}} className='form-control contact contact-input' type="number" placeholder='' name="weight" onChange={changeValue} />
                </div>
                <h3 style={{padding:"0", marginTop:"10px"}}><b>Height</b></h3>
                    <div>
                        <h3 style={{display:"inline-block", marginTop:"10px", width:"23%", minWidth:"150px", padding:"0"}}>{useMetric ? "Centimeters" : "Feet"}</h3>
                        <input style={{display:"inline-block", width:"auto"}} className='form-control contact contact-input' type="number" placeholder='' name="height" onChange={changeValue} />
                    </div>
                    {!useMetric ?
                        <div>
                            <h3 style={{display:"inline-block", marginTop:"10px", width:"23%", minWidth:"150px", padding:"0"}}>Inches</h3>
                            <input style={{display:"inline-block", width:"auto"}} className='form-control contact contact-input' type="number" placeholder='' name="inches" onChange={changeValue} />
                        </div> : 
                        <div></div>
                    }
            </div>
        </div>)
};

export default BMI