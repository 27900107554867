import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    BrowserRouter, Route, Navigate, Routes, Outlet,
    useParams
} from "react-router-dom";

function diagnosisRow(selected, d) {
    let isSelected = selected == d.link;

    return (
        <div className="col-md-6 col-sm-6" key={d.title}>
            <div className="pricing-style-one mb-3 wow fadeIn" data-wow-duration="1.0s" data-wow-delay="0.1s" style={{ minHeight: "200px", backgroundColor: isSelected ? "#EABB58" : "transparent" }}>
                <Link to={"/" + d.link} className="text-center">
                    <div className="pricing-icon text-center">
                        <img src={"../assets/" + d.image + ".png"} alt="" />
                    </div>
                    <div className="pricing-header text-center">
                        <p className="btn btn-primary">{d.title}</p>
                    </div>
                </Link>
            </div>
        </div>
    );
}


class Tools2 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            algorithms: [],
            selected: null
        };

    }

    render() {
        return (
            <div>
                <div className="App">
                    <header className="App-header">
                    </header>
                    <section id="pricing" className="pricing-area">
                        <div className="container">
                            <div className="row justify-content-center">
                                {this.state.algorithms.map((d) =>
                                    diagnosisRow(null, d)
                                )}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }

    componentDidMount() {
        var algorithms = require('../../data/toc.json');
        this.setState({ algorithms: algorithms });
    }
}

export default Tools2;
