import React from 'react'
import Tool from './Tool';
import Footer from '../../components/footer';

import {
    Link,
    useParams
} from "react-router-dom";
import colokey from '../../assets/images/color-key.png'

let toc = require('../../data/toc.json');
let algorithms = require('../../data/algorithms.json');
let diagnoses = require('../../data/diagnoses.json');

function link_safe(algorithm) {
    algorithm = algorithm.replace(/\,/g, '');
    algorithm = algorithm.replace(/\s+/g, '-');
    algorithm = algorithm.replace(/\//g, '-');
    return algorithm.toLowerCase();
}

function renderDiagnosis(d, p) {
    let key = p + d.title;
    var diagnosis = null;
    var algorithm = null;
    if (d.algorithm !== undefined && d.algorithm.length > 0) {
        algorithm = algorithms.find(a => a.title === d.algorithm);
        if (algorithm) {
            diagnosis = diagnoses.find(dn => dn.title === algorithm.diagnosis)
        }
    }

    return (
        <li key={key}>
            <p>
                {diagnosis && algorithm && algorithm.pdf && algorithm.pdf.length > 0 ? (
                    <Link to={"/" + link_safe(d.algorithm)}>{d.title}</Link>
                ) : d.title}
                {d.algorithm !== undefined && (!diagnosis || !algorithm || !algorithm.pdf || algorithm.pdf.length == 0) ? (
                    <span>- comming soon</span>
                ) : null}
            </p>
            {d && d.diagnoses ? (
                <ul className="data">
                    {d.diagnoses.map(dd => renderDiagnosis(dd, key + "/"))}
                </ul>) : null}
        </li>
    );
}

const Tools = ({ category }) => {

    let page = useParams()["*"];
    console.log([category, page]);

    if (page != "") {
        var pages = page.split('/');
        let algorithm = algorithms.find(a => link_safe(a.title) === pages[0]);
        var subtext = null;
        if (algorithm.population == "Geriatric") {
            subtext = "Geriatric decision support tool(s) do not account for special populations (eg renal failure) - further consideration beyond the scope of this tool may be necessary.";
        }
        let diagnosis = diagnoses.find(dn => dn.title === algorithm.diagnosis);

        return (
            <div>
                    <div className="container">
                        <Tool title={algorithm.title} location={page} />
                    </div>
            </div>
        );

    } else {

        let section = toc.find(a => a.link === category);

        return (
            <div>
                <section id="" className="about-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <div className="pricing-icon text-center">
                                    <img src={"../assets/" + section.image + ".png"} alt=""/>
                                </div>
                                <p><strong>If you're new to The Waco Guide, please be sure to read up on the </strong><a href="/methodology"><strong>intended use</strong></a><strong> of this decision-support tool, and make sure you’re looking at the </strong><a href="/all-tools"><strong>proper age range</strong></a><strong> for your patient.</strong></p>
                                <img style={{width:"100%", borderRadius:"18px"}} src={colokey} />
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div>
                                    <h3>{section.title}</h3>
                                    <ul className="data">
                                        {section.diagnoses.map(d => renderDiagnosis(d, ''))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div >
                </section>
                <Footer />
            </div>
        );
    }
}


export default Tools;