import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { render } from 'react-dom';
import Carousel from 'react-bootstrap/Carousel'
import ToolCheckbox from '../../pages/components/toolCheckBox';
import BlogCard from './flipCard';
import addResources from '../../assets/images/addResources.png';
import useGuide from '../../assets/images/useGuide.png';
import behind from '../../assets/images/behind.png';
import mobile from '../../assets/images/carousel-mobileApp.png';
import subAbuse from '../../assets/images/carousel-subAbuse.png';
import hospital from "../../assets/images/carousel-hospital.png";
import repro from "../../assets/images/carousel-reproductive.png";
import light from "../../assets/images/carousel-light.png";
import gear from "../../assets/images/carousel-gear.png";
import ama from "../../assets/images/carousel-ama.png";
import featured from "../../assets/images/featured.png";
import growingNeed from "../../assets/images/growing-need.jpg";
import googleStore from '../../assets/images/google.png';
import appleStore from '../../assets/images/appleStore.png';
import Card from './staticCard';
import Ball from './ball';
import { ballHelper } from './ballHelper';
import BallsApp from './BallsApp';


class WelcomeSelect extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            diagnosis:null
        }

        this.diagnosisChanged = this.diagnosisChanged.bind(this);
    }

    diagnosisChanged(event) {
        if(event.target.value == "Select Diagnosis...") {
            this.setState({diagnosis:null}); 
        } else {
            this.setState({diagnosis:event.target.value}); 
        }
    }

    makeBalls() {

        let ranNum = Math.floor(Math.random() * (15 - 12 + 1) + 15);
        if(window.innerWidth <= 768) {
            ranNum = ranNum/3
        }
        var ballAr = [];
        for(var i=0;i<=ranNum;i++) {
            ballAr.push(new Ball(ballHelper.getColor(), ballHelper.getRadius(), ballHelper.getAngle(), ballHelper.getSpeed()));
        }
        return ballAr;
    }

    componentDidMount(){
        window.scrollTo(0, 0);
    }

    render ()
    {
        let diagnoses = this.props.diagnoses;
        const balls = this.makeBalls();

        return(
            <div>
                <div>
                    <div className='row' style={{padding:"0", background:"white", borderRadius:"8px"}}>
                        <div style={{width:"100%", paddingBottom:"10px", paddingLeft:"0px", paddingRight:"0px"}}>
                            <div className='welcomeDiv'>
                                <div className='desktop'>
                                    <div className="row">
                                    <div className='column'></div>
                                    <div className='welcomeText column'><h1 className='homeTitleFont' style={{paddingTop:"100px"}}><b>PSYCHOPHARMACOLOGY<br/> IN PRIMARY CARE</b><br/></h1><h6>TOP-LEVEL EVIDENCE<br/>EXPERT OPINION<br/>TAILORED TO PRIMARY CARE®</h6></div>
                                    </div>
                                    <div className='grid3'>
                                        <Card imageName={useGuide} title="Step-by-Step Guided <br/>Tool View" description="Answer patient-focused questions to arrive at recommendations." link="/app"/>
                                        <Card imageName={behind} title="Comprehensive <br/>Tool View" description="View a disorder’s entire tool at a glance." link="/all-tools"/>
                                        <Card imageName={addResources} title="Additional Treatment Resources" description="Medication search, calculators, and more." link="/additional-resources"/>
                                    </div>
                                </div>
                                <div className='mobile'>
                                    <div style={{float:"left", width:"100%", padding:"10px 0px 10px 10px"}}>
                                        <h1 className='welcomeText'><b>PSYCHOPHARMACOLOGY<br/> IN PRIMARY CARE</b></h1>
                                    </div>
                                    <div className='row'>
                                        <div className='grid3'>
                                            <Card imageName={useGuide} title="Step-by-Step Guided <br/>Tool View" description="Answer patient-focused questions to arrive at recommendations." link="/app"/>
                                            <Card imageName={behind} title="Comprehensive <br/>Tool View" description="View a disorder’s entire tool at a glance." link="/all-tools"/>
                                            <Card imageName={addResources} title="Additional Treatment Resources" description="Medication search, calculators, and more." link="/additional-resources"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row desktop" style={{height:"50px", paddingLeft:"130px", paddingRight:"130px", background:"white", marginTop:"5px"}}>
                    </div>
                    <div style={{justifyContent:"center", background:"white", paddingBottom:"0px"}}>
                        <div className='carousel-home'>
                            <Carousel slide variant="dark" prevLabel="" nextLabel="">
                                <Carousel.Item>
                                    <a href="/team#special-thanks">
                                        <img
                                        className="d-block w-100"
                                        src={gear}
                                        alt="gear"
                                        />
                                    </a>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <a href="https://www.ama-assn.org/system/files/bhi-psychopharmacology-how-to-guide.pdf">
                                        <img
                                        className="d-block w-100"
                                        src={ama}
                                        alt="ama"
                                        />
                                    </a>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Link to="/methodology" >
                                        <img
                                        className="d-block w-100"
                                        src={light}
                                        alt="light"
                                        />
                                    </Link>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Link to="/all-tools" state={{tab:"Substance"}}>
                                        <img
                                        className="d-block w-100"
                                        src={subAbuse}
                                        alt="Substance Abuse Disorders"
                                        />
                                    </Link>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Link to="/all-tools" state={{tab:"Pregnancy"}}>
                                        <img
                                        className="d-block w-100"
                                        src={repro}
                                        alt="Reproductive"
                                        />
                                    </Link>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                    <div className="row desktop growingDiv">
                            <div className='welcomeText column'>
                                <h1 className='' style={{fontSize:"2.0vw", paddingTop:"110px", paddingLeft: "80px"}}><b>Behavioral health expertise at your fingertips</b></h1>
                                <p style={{paddingTop:"40px", paddingLeft: "80px", fontSize:"12pt"}}>The Waco Guide is developed by <Link to="/team">primary care experts</Link> in consultation with <a href="/team#special-thanks">faculty of the MGH Visiting through the Division of Professional and Public Education in the Department of Psychiatry</a>.
                                </p>
                            </div>
                    </div>
                </div>
                <div className='desktop' style={{padding:"80px 0px"}}>
                    <h4 style={{textAlign:"center"}}><b>Tools Included in the Guide</b></h4>
                    <div className='desktop home-card card' style={{width:"85%"}}>
                        <ToolCheckbox/>
                    </div>
                </div>
                <div>
                    <div className="wacofooter desktop" style={{padding:"50px 0px"}}>  
                    <div  className="row">
                        <div className='column' style={{width:"50%"}}></div>
                        <div  className='welcomeText column' style={{width:"50%"}}>
                            <h5 style={{padding:"10px 0px"}}><b>Download the Waco Guide app</b></h5>
                            <div className='grid-2' style={{gridGap:"0px"}}>
                            <div style={{width:"90%"}}>
                                <a href="https://apps.apple.com/us/app/waco-guide-psychopharmacology/id1503707099"><img src={appleStore} /></a>
                            </div>
                            <div style={{width:"90%"}}>
                                <a href="https://play.google.com/store/apps/details?id=org.wacoguide.app"><img src={googleStore} /></a>
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="mobile" style={{padding:"50px 0px"}}>  
                        <div className='welcomeText column' style={{width:"100%"}}>
                            <h5 style={{padding:"10px 0px"}}><b>Download the Waco Guide app</b></h5>
                            <div style={{width:"50%", paddingBottom:"15px"}}>
                                <a href="https://apps.apple.com/us/app/waco-guide-psychopharmacology/id1503707099"><img src={appleStore} /></a>
                            </div>
                            <div style={{width:"50%"}}>
                                <a href="https://play.google.com/store/apps/details?id=org.wacoguide.app"><img src={googleStore} /></a>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{backgroundColor:"#333333", margin:"auto", height:"65px"}}>
                        <div className='column' style={{width:"50%"}}><p style={{color:"white", paddingLeft:"15%", fontSize:"0.7vw"}}>Copyright Heart of Texas Community Health Center</p></div>
                        <div className='column' style={{width:"40%"}}>
                            <ul>
                                <li style={{display:"inline-block"}}><Link to="/faq"><p style={{color:"white", fontSize:"0.7vw"}}>Frequently Asked Questions</p></Link></li>
                                <li style={{display:"inline-block", color:"white", padding:"0px 10px"}}>|</li>
                                <li style={{display:"inline-block"}}><Link to="/about-guide"><p style={{color:"white", fontSize:"0.7vw"}}>About Us</p></Link></li>
                                <li style={{display:"inline-block", color:"white", padding:"0px 10px"}}>|</li>
                                <li style={{display:"inline-block"}}><Link to="/contact-us"><p style={{color:"white", fontSize:"0.7vw"}}>Contact Us</p></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WelcomeSelect;