import React from 'react'
import Segment from './segment';
import popImg from '../assets/images/comorbidities.png'

const Radio = ({ content, diagnosis, onSelected }) => {
    let c = content;

    let checkforHtml = () => {
        if(c.text != null) {
            let newText = "" + c.text;
            if(newText.includes("<b>") || newText.includes("<i>") || newText.includes("<br>")) {
                return(<div dangerouslySetInnerHTML={({__html: c.text})}></div>)
            } else {
                return((c.text || []).map((l, i) => (
                    <React.Fragment key={i}>{l}{i < (c.text.length - 1) ? <br /> : ''}</React.Fragment>
                )))
            }
        } else {
            return((c.text || []).map((l, i) => (
                <React.Fragment key={i}>{l}{i < (c.text.length - 1) ? <br /> : ''}</React.Fragment>
            )))
        }
    }

    return (
        <div className='question-content'>
        <h3 style={{padding:"0"}}>{c.text == "Special Populations" ? <img style={{width:"32px", marginRight:"10px"}} src={popImg}/> : null}{checkforHtml()}</h3>
            {c.groups ? c.groups.map((g) => {
                return (<Segment content={g} diagnosis={diagnosis} onSelected={onSelected} key={c.text} type={c.type} />)
            }) : ""}
        </div>)
};

export default Radio