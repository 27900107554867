import React, { useState } from 'react'
import { findDOMNode } from 'react-dom';
import plusImg from "../assets/images/plusBtn.png"
import minusImg from "../assets/images/minusBtn.png"

function insertHref(content) {
    var startIndex=content.lastIndexOf("https");
    var endIndex=content.lastIndexOf("html")+4;
    var str = content.substring(0,startIndex)+'<a href="'+content.substring(startIndex, endIndex)+'">'+content.substring(startIndex, endIndex)+'</a>'+content.substring(endIndex);
    return(str)
}

function htmlAsDiv(content) {
    var html = content.replace(/\r?\n/g, '<br />');
    return (<div dangerouslySetInnerHTML={{ __html: html}} />);
}

const TherapyIos = ({ therapy, showClass, index }) => {
    const plus = plusImg
    const minus = minusImg
    const expandImg = { plus, minus }
    const [selected, setSelected] = useState(expandImg.plus)
    
    function getImageName(e) {
        if(e != null) {
            var isExpanded = e.target.getAttribute("aria-expanded");
            
            if(isExpanded == "true") {
                setSelected(expandImg.minus);
                return minusImg;
            } else if(isExpanded == "false"){
                setSelected(expandImg.plus);
                return plusImg;
            }
        }
        setSelected(expandImg.plus)
        return plusImg;
    }

    let section = "collapseMed" + index;
    let hsection = "#" + section;
    
    if(therapy.description != null && therapy.description != "") {
        
        return (<React.Fragment>
            <div key={therapy.name} className={"med-card med-body odd"} style={{marginRight: "30px"}} data-toggle="collapse" data-bs-toggle="collapse" href={hsection} role="button" aria-expanded="false" aria-controls={section} onClick={e => getImageName(e)}>
                <div className='row ignoreClick' style={{paddingLeft:"10px"}}>
                    <div className='columnMeds'>
                        <b>{therapy.name}</b>
                    </div>
                    <div className='columnMeds'>
                            {therapy.class && therapy.class.length > 0 ? (
                            <span><i>{therapy.class}</i></span>
                    ) : null}
                    </div>
                    <div className='column10'>
                        <img style={{width:"70%", float:"right"}} src={selected}/>
                    </div>
                </div>
                <div className={"card0 card0-body odd"}>
                <div className="collapse" id={section}>
                <div style={{width:"100%", height:"1px", backgroundColor:"#7a7a7a"}}></div>
                <div className="row">
                    {(<div>
                        <br /><b>{htmlAsDiv(therapy.description)}</b><br />
                    </div>
                    )}
                </div>
            </div>
            </div>
            </div>
            
        </React.Fragment>);
    } else {
        return (<React.Fragment>
            <div key={therapy.name} className={"med-card med-body odd"} style={{marginRight: "30px"}} data-toggle="collapse" data-bs-toggle="collapse" href={hsection} role="button" aria-expanded="false" aria-controls={section} onClick={e => getImageName(e)}>
                <div className='row ignoreClick' style={{paddingLeft:"10px"}}>
                    <div className='columnMeds'>
                        <b>{therapy.name}</b>
                    </div>
                    <div className='columnMeds'>
                            {therapy.class && therapy.class.length > 0 ? (
                            <span><i>{therapy.class}</i></span>
                    ) : null}
                    </div>
                </div>
            </div>
            
        </React.Fragment>);
    }
};

export default TherapyIos