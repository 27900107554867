import React from 'react'
import { getVariable } from '../service/waco';

const Checkboxes = ({ content, onSelected }) => {
    let c = content;
    let onChecked = (event) => {
        let id = event.target.name;
        let value = event.target.checked;
        onSelected(id, { text: value });
    }


    return (
        <div className="question question-content" key={c.text}>
            {c.choices.map((o) => {
                if(getVariable((c.id + "_" + o.value)) == true) {
                    return(
                    <div key={o.text} className="radioDiv" >
                        <input className="radio" type="checkbox" id={o.text} name={(c.id + "_" + o.value)} value={o.value} onChange={onChecked} checked/>
                        <label style={{fontSize:"14pt"}}  className="radioLabel" htmlFor={o.text}>{o.text}</label>
                    </div>  )
                } else {
                    return(
                    <div key={o.text} className="radioDiv" >
                        <input className="radio" type="checkbox" id={o.text} name={(c.id + "_" + o.value)} value={o.value} onChange={onChecked}/>
                        <label style={{fontSize:"14pt"}}  className="radioLabel" htmlFor={o.text}>{o.text}</label>
                    </div>  )
                }
            })}
        </div>
    )
};

export default Checkboxes