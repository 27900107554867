import React from 'react'
import tallMan from "../service/tallMan.js"

var iii = 0;

function htmlAsDiv(content) {
    var html = content.replace(/\r?\n/g, '<br />');
    return (<div dangerouslySetInnerHTML={{ __html: html}} />);
}

const Medication = ({ medication, showClass }) => {
    iii += 1;
    let section = "collapseMed" + iii;
    let hsection = "#" + section;
    let emoji = medication.alert && medication.alert.length > 0 ? "⚠️" : "";

    return (<React.Fragment>
        <tr key={medication.name} className={"card0 card0-body " + (iii % 2 == 1 ? "odd" : "even")} style={{borderTop: "1px solid black"}} data-toggle="collapse" data-bs-toggle="collapse" href={hsection} role="button" aria-expanded="false" aria-controls={section}>
        <td style={{minWidth:"180px"}} >
            <b>{emoji} {tallMan.getTallMed(medication.name)}</b>
            {showClass ? (<i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{medication.class}</i>) : null}
        </td>
        <td style={{minWidth:"280px"}}>
        {medication.class && medication.class.length > 0 ? (
                   <span>{medication.class}</span>
        ) : null}
        </td>
        <td style={{minWidth:"180px"}}>
        {medication.brands && medication.brands.length > 0 && medication.brands != medication.name ? (
                   <span>{medication.brands}</span>
        ) : null}
        </td>
        <td width="100%">
        </td>
        </tr>
        <tr className={"card0 card0-body " + (iii % 2 == 1 ? "odd" : "even")}>
            <td colSpan="4">
            <div className="collapse" id={section}>
            <div className="">
                {medication.dosing && medication.dosing.length > 0 ? (<div>
                    <br /><b>{htmlAsDiv(medication.dosing)}</b><br />
                </div>
                ) : null}
                <div align="center">
                    <table>
                        <tbody>
                <tr>
                    <td style={{padding: "20px", background: "#ccc", verticalAlign:"top"}}>
                {medication.side_effects_common && medication.side_effects_common.length > 0 ? (<div>
                    <b>Side effects (common):</b> {htmlAsDiv(medication.side_effects_common)}
                </div>
                ) : null}
                </td>
                <td style={{padding: "20px", background: "#ccc", verticalAlign:"top"}}>
                {medication.side_effects_rare && medication.side_effects_rare.length > 0 ? (<div>
                    <b>Side effects (rare/serious):</b> {htmlAsDiv(medication.side_effects_rare)}
                </div>
                ) : null}
                </td>
                </tr>
                </tbody>
                </table></div>
               {medication.notes && medication.notes.length > 0 ? (<div>
                    <br /><b>Notes:</b> {htmlAsDiv(medication.notes)}
                </div>
                ) : null}
                {medication.monitor && medication.monitor.length > 0 ? (<div>
                     <br /><b>Monitor:</b> {htmlAsDiv(medication.monitor)}
                 </div>
                 ) : null}
                 {medication.info && medication.info.length > 0 ? (<div>
                      <br /><b>Additional Info:</b> {htmlAsDiv(medication.info)}
                  </div>
                  ) : null}
            </div>
        </div>
        </td>
        </tr>
    </React.Fragment>);
};

export default Medication