import React, { Component } from "react";
import { Link } from "react-router-dom";
import AlgoPage from "../../components/page";
import waco from "../../service/waco";
import "../management/app.css";

function sortByKey(array, key) {
    return array.sort(function(a, b) {
        var x = a[key]; var y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
}

let algorithms = require("../../data/algorithms.json");
class ToolCheckbox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            filter: 'Adult'
        };
        this.pageTravel = this.pageTravel.bind(this);
        this.pageLinks = this.pageLinks.bind(this);
        this.goToPage = this.goToPage.bind(this);
        this.renderTools = this.renderTools.bind(this);
        this.link_safe = this.link_safe.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
    }

    pageLinks = (page, num_pages) => {
        var links = [];
        for(var i=0;i<num_pages;i++) {
            if (page == i) {
                links.push(<span><b><u>{i+1}</u></b>&nbsp;</span>);
            } else {
                let pp = i;
                links.push(<span><a href="" onClick={(e) => this.goToPage(e, pp)}>{i+1}</a>&nbsp;</span>);
            }
        }
        return (<span>{links}</span>);
    }

    render() {
        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }

        var validTools = [];
        var allOff = false;
        var t = this.state.filter;
        if(t == "Substance Use Disorder") {
            validTools = validTools.concat(
                algorithms.filter(c => (c.algorithm == "aud" || c.algorithm == "benzo" || c.algorithm == "oud" || c.algorithm == "stimulant" || c.algorithm == "tud"))
            );
        } else {
            validTools = validTools.concat(
                algorithms.filter(c => c.population == t)
            );
        }

        validTools = validTools.filter(onlyUnique);
        sortByKey(validTools, 'title');

        // 4x6 rows
        const pages = [];
        var page = [];
        var row = [];
        for (let i = 0; i < validTools.length; i++) {
            //Skip duplicate tools or tools with no algorithms (Currently AUD Fixed Dosing, Anti Side effect management split, and OUD subcategories)
            if(validTools[i].algorithm == null || validTools[i].title.includes("Metabolic and Medical Disorders") || validTools[i].title.includes("Movement Related Disorders") || validTools[i].title.includes("Opioid Use Disorder:")) {
                continue;
            }
            row.push(this.renderTools(validTools[i]));
            if (row.length == 3) {
                page.push(row);
                row = [];

                if (page.length == 100) {
                    pages.push(page);
                    page = [];
                }
            }
        }

        if (row.length > 0) {
            page.push(row);
        }
        if (page.length > 0) {
            pages.push(page);
        }

        page = [];
        if (pages.length > 0 && this.state.page >= pages.length) {
            this.state.page = pages.length - 1;
        }
        if (pages.length > this.state.page) {
            page = pages[this.state.page];
        }

        return (
            <div style={{ height: "100%" }}>
                <div className="row" style={{ height: "100%" }}>
                    <div className="column" style={{ width: "23%", paddingTop: "25px" }}>
                        <h6 style={{ paddingBottom: "5px" }}><b>Select category type</b></h6>
                        <ul className="toolCheckList">
                            <li>
                                <label className="toolCheckItem">
                                    <input style={{ width: "15px", height: "15px" }} type="radio" name="filter" value="Adult" onChange={this.changeFilter} defaultChecked={true} />  Adult
                                </label>
                            </li>
                            <li>
                                <label className="toolCheckItem">
                                    <input style={{ width: "15px", height: "15px" }} type="radio" name="filter" value="Pediatric" onChange={this.changeFilter} />  Pediatric
                                </label>
                            </li>
                            <li>
                                <label className="toolCheckItem">
                                    <input style={{ width: "15px", height: "15px" }} type="radio" name="filter" value="Geriatric" onChange={this.changeFilter} />  Geriatric
                                </label>
                            </li>
                            <li>
                                <label className="toolCheckItem">
                                    <input style={{ width: "15px", height: "15px" }} type="radio" name="filter" value="Preconception" onChange={this.changeFilter} />  Preconception
                                </label>
                            </li>
                            <li>
                                <label className="toolCheckItem">
                                    <input style={{ width: "15px", height: "15px" }} type="radio" name="filter" value="Antepartum" onChange={this.changeFilter} />  Antepartum
                                </label>
                            </li>
                            <li>
                                <label className="toolCheckItem">
                                    <input style={{ width: "15px", height: "15px" }} type="radio" name="filter" value="Postpartum" onChange={this.changeFilter} />  Postpartum
                                </label>
                            </li>
                            <li>
                                <label className="toolCheckItem">
                                    <input style={{ width: "15px", height: "15px" }} type="radio" name="filter" value="Cardiac" onChange={this.changeFilter} />  Cardiac
                                </label>
                            </li>
                            <li>
                                <label className="toolCheckItem">
                                    <input style={{ width: "15px", height: "15px" }} type="radio" name="filter" value="Hepatic" onChange={this.changeFilter} />  Hepatic
                                </label>
                            </li>
                            <li>
                                <label className="toolCheckItem">
                                    <input style={{ width: "15px", height: "15px" }} type="radio" name="filter" value="Renal" onChange={this.changeFilter} />  Renal
                                </label>
                            </li>
                            <li>
                                <label className="toolCheckItem">
                                    <input style={{ width: "15px", height: "15px" }} type="radio" name="filter" value="Obesity" onChange={this.changeFilter} />  Obesity
                                </label>
                            </li>
                            <li>
                                <label className="toolCheckItem">
                                    <input style={{ width: "15px", height: "15px" }} type="radio" name="filter" value="Substance Use Disorder" onChange={this.changeFilter} />  Substance Use Disorder
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div className="column verticleLine" style={{ width: "1%" }}></div>
                    <div className="column" style={{ width: "74%" }}>
                        <table className="toolpage" style={{ marginTop: "20px" }}>
                            <tbody>
                                {allOff ? (<tr style={{width: "100%"}}><td><h5>Select a category to get started</h5></td></tr>) : ""}
                                {page.map(p => (<tr style={{width: "100%"}}>{p}</tr>))}
                                {allOff || pages.length == 1 ? "" : (<tr><td colSpan="3" style={{width: "100%", textAlign: "right"}}>
                                    {this.state.page > 0 ? (<a href="" onClick={(e) => this.pageTravel(e, -1)}>Previous</a>) : "Previous"} | {this.pageLinks(this.state.page, pages.length)}| {this.state.page < pages.length ? (<a href="" onClick={(e) => this.pageTravel(e, 1)}>Next</a>) : "Next"}
                                    </td></tr>)}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        );
    }

    link_safe = (algorithm) => {
        algorithm = algorithm.replace(/\,/g, '');
        algorithm = algorithm.replace(/\s+/g, '-');
        algorithm = algorithm.replace(/\//g, '-');
        return algorithm.toLowerCase();
    }

    changeFilter = (newFilter) => {
        var data = {
            page: 0,
            filter: newFilter.target.value
        };
        this.setState(data);
    }

    pageTravel = (e, amount) => {
        e.preventDefault();
        var page = this.state.page + amount;
        if (page < 0) { 
            page = 0;
        }
        this.setState({page:  page});
    }

    goToPage = (e, page) => {
        e.preventDefault();
        if (page < 0) { 
            page = 0;
        }
        this.setState({page:  page});
    }

    renderTools = (algo) => {
        return (
            <td key={algo.title} style={{verticalAlign: "top", width: "33%"}}>
                <b>{algo.title}</b>
                <br />
                <Link to={"/app/" + this.link_safe(algo.title)} style={{ fontSize: "10pt" }}>View Tool</Link>
            </td>
        )
    }
}

export default ToolCheckbox;
