import React, { Component } from "react";
import adultImg from '../../assets/images/adult_small.png';



class BlogCard extends Component {
    constructor(props) {
      super(props);
      this.state = { flipped: false, canFlip: true };
      this.flip = this.flip.bind(this);
    }
  
    componentDidMount() {
      if(this.props.canFlip == false) {
        this.setState({canFlip: this.props.canFlip})
      }
    }

    flip = () => {
      if(this.state.canFlip) {
        this.setState({ flipped: !this.state.flipped });
      }
    }
    render() {
      return (
        <div onMouseEnter={this.flip} onMouseLeave={this.flip} className={"card-container" + (this.state.flipped ? " flipped" : "")}>
  
          <Front imageName={this.props.imageName} frontText={this.props.frontText} />
          <Back imageName={this.props.imageName} backText={this.props.backText}/>
        </div>
  
      )
    }
  }

  export default BlogCard
  
  class Front extends Component {
    constructor(props) {
        super(props);
      }
    render() {
      return (
        <div className="front">
          <img className="flipImg-Front" src={this.props.imageName}/>
          <b className="cardText centered" style={{whiteSpace:"pre-line"}}>{this.props.frontText}</b>
        </div>
      )
    }
  }
  
  class Back extends Component {
    constructor(props) {
        super(props);
      }
    render() {
      return (
        <div className="back">
          <img className="flipImg-Back" src={this.props.imageName}/>
          <p className="centered">{this.props.backText}</p>
        </div>
      )
    }
  }
  