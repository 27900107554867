import React, { Component } from 'react';
import { Document, Page } from 'react-pdf';
import { Link } from 'react-router-dom';
import Medication from '../../components/medication';
import waco, { setAlgorithm } from '../../service/waco';
import MPage from '../../components/page';
import Management from '../management';

class ManagementOld extends Component {

    constructor(props) {
        super(props);

        console.log('Management 2');
        this.state = {
            page: {},
            diagnoses: [],
            state: "intro"
        };

        this.setVariable = this.setVariable.bind(this);
        this.getVariable = this.getVariable.bind(this);
        this.goToNextPage = this.goToNextPage.bind(this);
    }

    setVariable = (id, value) => {
        waco.setVariable(id, value);
        var data = waco.reevaluate(this.state.state);
        data.state = this.state.state;
        this.setState({ page: data });
    }

    getVariable = (id) => {
        return waco.getVariable(id)
    }

    goToNextPage = () => {

        let page = this.state.page;
        let symptoms = page.variables.symptoms

        var nextState = this.state.state;
        if (nextState == "intro") {
            nextState = "medication"
        } else if (nextState == "medication") {
            nextState = "recommendation"
        }

        var data = waco.reevaluate(nextState);
        data.state = nextState;
        this.setState({ page: data, state: nextState });
    }

    render() {
        return (
            <div>
                <div className="App">
                    <MPage
                        page={this.state.page}
                        state={this.state.state}
                        diagnoses={this.state.diagnoses}
                        setVariable={this.setVariable}
                        getVariable={this.getVariable}
                        goToNextPage={this.goToNextPage} />
                </div>
            </div>
        )
    }

    componentDidUpdate(prevProps) {
        if (prevProps.algo !== this.props.algo) {
            this.loadIt(this.props.algo);
        }
      }
   
      loadIt(algo) {
          if (!algo) {
              return;
          }

          console.log(algo.algorithm);
          try {
            var json = require('../../data/' + algo.algorithm + '.json');
            console.log(algo.algorithm);
            console.log(json);
            console.log(json);
            waco.setAlgorithm(json);
            waco.setVariable('comorbidities', '');
            waco.setVariable('patient_age','Adult');
            waco.setVariable('partum','None');
            waco.setVariable('partum','None');
            waco.setVariable("diagnosis", algo.diagnosis);

            var data = waco.reevaluate("intro");
            data.state = "intro";
            console.log(data);
            this.setState({ page: data, state: "intro" });

            console.log(window.location.href);
            var url = new URL(window.location.href);
            var c = url.searchParams.get("context");
            if (c) {
                var datas = c.split('.');
                if (datas.length > 2) {
                    var params = atob(datas[1]);
                    var data = JSON.parse(params);
                    console.log(data);

                    fetch('https://api.clinicalalgorithms.com/v1/data/' + data.source, { 
                        method: 'GET', 
                        mode: 'cors', // no-cors
                        headers: new Headers({
                            'Authorization': 'Bearer ' + c, 
                            'Accept': 'application/json'
                        }), 
                    }).then( response => {
                        if (!response.ok) {
                            throw new Error("HTTP error " + response.status);
                        }
                        return response.json();
                    }).then(json => {
                        
                        var vars = Object.keys(json.facts);
                        for(var i=0;i<vars.length;i+=1) {
                            var fid = vars[i];
                            var fact = json.facts[fid];
                            if (fact !== null) {
                              var fvalue = fact.value;
                              waco.setVariable(fid, fvalue);
                            }
                        }

                        var data = waco.reevaluate("intro");
                        data.state = "intro";
                        console.log(data);
                        this.setState({ page: data, state: "intro" });
                    });
                }                
            }    

        } catch (ex) {
              console.log(ex);
          }
      }


    componentDidMount() {
        if (!this.props.algo) {
            return;
        }

        this.loadIt(this.props.algo);
    }
}


class Tool extends Component {

    constructor(props) {
        super(props);

        this.state = {
            active: false,
            algorithm: null,
            classes: [],
            medications: []
        };

    }

    render() {
        
        return (
            <div>
                <div className="row justify-content-center d-block">
                    <Management algo={this.state.algorithm} />
                </div>
            </div>
        )
    }


    componentDidMount() {
        var algorithms = require('../../data/algorithms.json');
        let diagnoses = require('../../data/diagnoses.json');
        var medications = require('../../data/medications.json');
        let a = algorithms.filter(a => a.title == this.props.title);

        if (a.length > 0) {
            let algo = a[0];
            let diagnosis = diagnoses.find(dn => dn.title === algo.diagnosis)
            if (diagnosis && diagnosis.active) {
                let population = algo.population.toLocaleLowerCase();
                medications = medications.filter(m => m.diagnosis == algo.diagnosis &&
                    m.population.includes(population));
                var dd = medications.reduce((acc, d) => {
                    if (Object.keys(acc).includes(d.class)) return acc;

                    acc[d.class] = medications.filter(g => g.class === d.class);
                    return acc;
                }, {})
                var classes = Object.keys(dd).sort();

                this.setState({ algorithm: algo, medications: dd, classes: classes, active: true });
            } else {
                // todo
                this.setState({ algorithm: algo, active: false });
            }
        }
    }
}

export default Tool;
